import { createRouter, createWebHistory, RouteComponent, RouteRecordRaw } from 'vue-router'
import { TextViewSideBarAdditionalBoxTypesEnum } from '@/enums/TextViewSideBarAdditionalBoxTypes.enum';
import HomeView from '@/views/HomeView.vue';

const PAGE_TITLE_SUFFIX = 'BLZ Scherz + Cramer';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      tabTitle: 'Startseite'
    }
  },
  {
    path: '/suche',
    name: 'Search',
    component: (): Promise<RouteComponent> => import('../views/SearchView.vue'),
    meta: {
      label: 'suche',
      tabTitle: 'Suche'
    }
  },
  {
    path: '/leistungsbereiche',
    name: 'Performance',
    component: (): Promise<RouteComponent> => import('../views/performance/PerformanceAreas.vue'),
    meta: {
      label: 'leistungsbereiche',
      tabTitle: 'Wir bieten Lösungen für ihr Büro'
    },
    children: [
      {
        path: 'service-angebote/:accordion?',
        name: 'ServicesOffered',
        component: (): Promise<RouteComponent> => import('../views/performance/ServicesOffered.vue'),
        meta: {
          layout: 'default-text-view',
          pageId: 0,
          sidebarElements: [TextViewSideBarAdditionalBoxTypesEnum.CONTACT_TEAM, TextViewSideBarAdditionalBoxTypesEnum.OWNER],
          label: 'service-angebote',
          tabTitle: 'Das bieten unsere Service-Angebote'
        }
      },
      {
        path: 'mobile-print-loesungen',
        name: 'MobilePrintSolutions',
        component: (): Promise<RouteComponent> => import('../views/performance/MobilePrintSolutions.vue'),
        meta: {
          layout: 'default-text-view',
          pageId: 1,
          sidebarElements: [TextViewSideBarAdditionalBoxTypesEnum.CONTACT_TEAM, TextViewSideBarAdditionalBoxTypesEnum.OWNER],
          label: 'mobile-print-loesungen',
          tabTitle: 'Mobile und flexible Print-Lösungen'
        }
      },
      {
        path: 'drucken-kopieren-scannen',
        name: 'PrintCopyScan',
        component: (): Promise<RouteComponent> => import('../views/performance/PrintCopyScan.vue'),
        meta: {
          layout: 'default-text-view',
          pageId: 2,
          sidebarElements: [TextViewSideBarAdditionalBoxTypesEnum.CONTACT_TEAM, TextViewSideBarAdditionalBoxTypesEnum.OWNER, TextViewSideBarAdditionalBoxTypesEnum.SERVICES],
          label: 'drucken-kopieren-scannen',
          tabTitle: 'Drucker, Kopierer und Scanner'
        }
      },
      {
        path: 'archivierung-und-workflowmanagement',
        name: 'ArchivingAndWorkflowManagement',
        component: (): Promise<RouteComponent> => import('../views/performance/ArchivingAndWorkflowManagement.vue'),
        meta: {
          layout: 'default-text-view',
          pageId: 3,
          sidebarElements: [TextViewSideBarAdditionalBoxTypesEnum.CONTACT_TEAM, TextViewSideBarAdditionalBoxTypesEnum.OWNER],
          label: 'archivierung-und-workflowmanagement',
          tabTitle: 'Archivierung und Workflowmanagement'
        }
      },
      {
        path: 'individuelle-telefonieloesungen',
        name: 'IndividualTelephonySolutions',
        component: (): Promise<RouteComponent> => import('../views/performance/IndividualTelephonySolutions.vue'),
        meta: {
          layout: 'default-text-view',
          pageId: 4,
          sidebarElements: [TextViewSideBarAdditionalBoxTypesEnum.CONTACT_TEAM, TextViewSideBarAdditionalBoxTypesEnum.OWNER, TextViewSideBarAdditionalBoxTypesEnum.TELEPHONY],
          label: 'individuelle-telefonieloesungen',
          tabTitle: 'Individuelle Telefonielösungen'
        }
      },
      {
        path: 'netzwerk-it-und-edv',
        name: 'NetworkITAndEDV',
        component: (): Promise<RouteComponent> => import('../views/performance/NetworkITAndEDV.vue'),
        meta: {
          layout: 'default-text-view',
          pageId: 5,
          sidebarElements: [TextViewSideBarAdditionalBoxTypesEnum.CONTACT_TEAM, TextViewSideBarAdditionalBoxTypesEnum.OWNER],
          label: 'netzwerk-it-und-edv',
          tabTitle: 'Netzwerke, IT- & EDV-Services'
        }
      },
      {
        path: 'bueroeinrichtung',
        name: 'OfficeFurnishings',
        component: (): Promise<RouteComponent> => import('../views/performance/OfficeFurnishings.vue'),
        meta: {
          layout: 'default-text-view',
          pageId: 6,
          sidebarElements: [TextViewSideBarAdditionalBoxTypesEnum.CONTACT_TEAM, TextViewSideBarAdditionalBoxTypesEnum.PRODUCT],
          label: 'bueroeinrichtung',
          tabTitle: 'Büroeinrichtung vom Experten'
        }
      },
      {
        path: 'einrichtungsplanung',
        name: 'FacilityPlanning',
        component: (): Promise<RouteComponent> => import('../views/performance/FacilityPlanning.vue'),
        meta: {
          layout: 'default-text-view',
          pageId: 7,
          sidebarElements: [TextViewSideBarAdditionalBoxTypesEnum.CONTACT_TEAM, TextViewSideBarAdditionalBoxTypesEnum.BENEFITS],
          label: 'einrichtungsplanung',
          tabTitle: 'Einrichtungsplanung vom Experten'
        }
      }
    ]
  },
  {
    path: '/produkte',
    name: 'Products',
    component: (): Promise<RouteComponent> => import('../views/products/Products.vue'),
    meta: {
      label: 'produkte',
      tabTitle: 'Produkte'
    },
    children: [
      {
        path: 'kopierer-mfp',
        name: 'CopierMfp',
        component: (): Promise<RouteComponent> => import('../views/DefaultProductView.vue'),
        meta: {
          typeId: 5,
          label: 'kopierer-mfp',
          layout: 'default-product-view',
          tabTitle: 'Kopierer / MFP'
        }
      },
      {
        path: 'vb-material-kopierer-mfp',
        name: 'VbMaterialCopierMfp',
        component: (): Promise<RouteComponent> => import('../views/DefaultProductView.vue'),
        meta: {
          typeId: 0,
          label: 'vb-material-kopierer-mfp',
          layout: 'default-product-view',
          tabTitle: 'VB-Material Kopierer / MFP'
        }
      },
      {
        path: 'drucker',
        name: 'Printer',
        component: (): Promise<RouteComponent> => import('../views/DefaultProductView.vue'),
        meta: {
          typeId: 6,
          label: 'drucker',
          layout: 'default-product-view',
          tabTitle: 'Drucker'
        }
      },
      {
        path: 'vb-material-drucker',
        name: 'VbMaterialPrinter',
        component: (): Promise<RouteComponent> => import('../views/DefaultProductView.vue'),
        meta: {
          typeId: 1,
          label: 'vb-material-drucker',
          layout: 'default-product-view',
          tabTitle: 'VB-Material Drucker'
        }
      },
      {
        path: 'produktionssysteme',
        name: 'ProductionSystems',
        component: (): Promise<RouteComponent> => import('../views/DefaultProductView.vue'),
        meta: {
          typeId: 7,
          label: 'produktionssysteme',
          layout: 'default-product-view',
          tabTitle: 'Produktionssysteme'
        }
      },
      {
        path: 'grossformat-plotter',
        name: 'LargeFormatPlotter',
        component: (): Promise<RouteComponent> => import('../views/DefaultProductView.vue'),
        meta: {
          typeId: 9,
          label: 'großformat-plotter',
          layout: 'default-product-view',
          tabTitle: 'Großformat / Plotter'
        }
      },
      {
        path: 'software',
        name: 'Software',
        component: (): Promise<RouteComponent> => import('../views/DefaultProductView.vue'),
        meta: {
          typeId: 2,
          label: 'software',
          layout: 'default-product-view',
          tabTitle: 'Software'
        }
      },
      {
        path: 'telefonanlagen',
        name: 'TelephoneSystems',
        component: (): Promise<RouteComponent> => import('../views/DefaultProductView.vue'),
        meta: {
          typeId: 4,
          label: 'telefonanlagen',
          layout: 'default-product-view',
          tabTitle: 'Telefonanlagen'
        }
      },
      {
        path: 'it-pc-server',
        name: 'ItPcServer',
        component: (): Promise<RouteComponent> => import('../views/DefaultProductView.vue'),
        meta: {
          typeId: 8,
          label: 'it-pc-server',
          layout: 'default-product-view',
          tabTitle: 'IT / PC / Server'
        }
      },
      {
        path: 'sitzmobel',
        name: 'SeatingFurniture',
        component: (): Promise<RouteComponent> => import('../views/DefaultProductView.vue'),
        meta: {
          typeId: 3,
          label: 'sitzmöbel',
          layout: 'default-product-view',
          tabTitle: 'Sitzmöbel'
        }
      },
      {
        path: 'detail/:product_id',
        name: 'ProductDetails',
        component: (): Promise<RouteComponent> => import('../views/products/ProductDetails.vue'),
        meta: {
          layout: 'product-details',
          sidebarElements: [TextViewSideBarAdditionalBoxTypesEnum.PRODUCT_CONTACT_TEAM],
          similarProducts: true,
          tabTitle: 'Drucker'
        }
      },
      {
        path: 'produktform/:product_id',
        name: 'ProductForm',
        component: (): Promise<RouteComponent> => import('../views/products/ProductForm.vue'),
        meta: {
          layout: 'product-details',
          sidebarElements: [TextViewSideBarAdditionalBoxTypesEnum.PRODUCT_CONTACT_TEAM],
          similarProducts: false
        }
      }
    ]
  },
  {
    path: '/branchenloesungen',
    name: 'IndustrySolutions',
    component: (): Promise<RouteComponent> => import('../views/DefaultTextView.vue'),
    meta: {
      label: 'branchenlösungen',
      tabTitle: 'Büro-Lösungen auch für ihre Branche'
    },
    children: [
      {
        path: '',
        name: 'IndustrySolutionsChild',
        component: (): Promise<RouteComponent> => import('../views/industrySolutions/IndustrySolutions.vue'),
        meta: {
          pageId: 8,
          sidebarElements: [TextViewSideBarAdditionalBoxTypesEnum.CONTACT_TEAM, TextViewSideBarAdditionalBoxTypesEnum.OWNER]
        }
      }
    ]
  },
  {
    path: '/uber-uns',
    name: 'AboutUs',
    meta: {
      label: 'über uns',
      tabTitle: 'Experten für Bürotechnik seit 1910'
    },
    children: [
      {
        path: '',
        name: 'AboutUs ',
        component: (): Promise<RouteComponent> => import('../views/aboutUs/AboutUsView.vue')
      },
      {
        path: 'historie',
        name: 'History',
        component: (): Promise<RouteComponent> => import('../views/aboutUs/HistoryView.vue'),
        meta: {
          label: 'historie',
          tabTitle: 'BLZ Scherz + Cramer - Experten seit 1910'
        }
      },
      {
        path: 'team-service',
        name: 'TeamAndService',
        component: (): Promise<RouteComponent> => import('../views/aboutUs/TeamAndServices.vue'),
        meta: {
          label: 'team & service',
          tabTitle: 'Unsere Servicespezialisten für sie'
        }
      },
      {
        path: 'karriere',
        name: 'Career',
        component: (): Promise<RouteComponent> => import('../views/aboutUs/Career.vue'),
        meta: {
          label: 'Karriere',
          tabTitle: 'Karriere'
        }
      }
    ]
  },
  {
    path: '/job-anziege/vertriebsbeauftragten',
    name: 'Sales representative',
    component: (): Promise<RouteComponent> => import('../views/aboutUs/JobOffer.vue'),
    meta: {
      jobOffer: {
        backgroundImage: 'sales-representative.webp',
        imageTitle: 'Vertriebsbeauftragten (m/w)',
        taskDescription: 'Als Verkaufsberater (w/m/d) im Außendienst geht es nicht nur um Neukundenakquise, sondern darum die Beziehung zu Ihrem bestehenden Kundenstamm zu pflegen und vor allem weiter auszubauen. Sie sind eigenverantwortlich für das strategische und operative Geschäft Ihres Verkaufsgebietes zuständig. Dabei analysieren Sie den Markt, die Wettbewerbsaktivitäten, sowie das Potential Ihrer Kunden und bauen Ihre Vertriebsstrategie stetig aus. Aufbauend auf den Ergebnissen Ihrer Analyse und Ihrer effizienten Termin- und Routenplanung, schaffen Sie es täglich nah an Ihren Kunden zu sein und diese zu begeistern. Sie finden für jeden individuellen Bedarf Ihrer Kunden die jeweils effizienteste Lösung. Sie demonstrieren die innovativsten Produkte der Branche und vermarkten hochmoderne Lösungen sowie alle Dienstleistungen und Services.',
        skills: [
          'Kaufmännische/technische oder handwerkliche Ausbildung',
          'Praktische Erfahrungen im B2B Vertrieb',
          'Erfahrungen bei der Bestandskundenbetreuung sowie der Neukundengewinnung, vorzugsweise bei Geschäftsführern und Inhabern von mittelständischen Unternehmen',
          'Eigeninitiative und Eigenmotivation',
          'Strukturierte Arbeitsweise',
          'Klarer Auftritt & Kommunikationsfähigkeiten',
          'Verhandlungsgeschick',
          'Kundenorientiert',
          'Kommunikationsfähig',
          'Lust auf neues'
        ],
        offers: [
          'Sicherer Arbeitsplatz',
          'Urlaubs- und Weihnachtsgeld',
          'Eine attraktive leistungsorientierte Vergütung',
          'Arbeiten innerhalb eines familiär geprägten Teams',
          '24 Monate Absicherung der Vergütung während der Einarbeitungszeit',
          'Firmenwagen',
          'Kontinuierliche Weiterbildung in ihren persönlichen Stärken',
          'Betriebliche Altersvorsorge'
        ]
      },
      tabTitle: 'Vertrieb w/m'
    }
  },
  {
    path: '/job-anziege/technik',
    name: 'Technician IT',
    component: (): Promise<RouteComponent> => import('../views/aboutUs/JobOffer.vue'),
    meta: {
      jobOffer: {
        backgroundImage: 'technician-it.webp',
        imageTitle: 'IT-Servicetechniker w/m',
        taskDescription: 'Aufbau und Abbau von Hardware, IT Systemen, PC, Notebook, MFPs sowie Drucker Installation von Hardware und Software (Windows Betriebssysteme, MS-Office, Exchange- Server, VM + Hyper-Ware, Flottenmanagement, Follow Print Lösungen, DMS-Lösungen sowie Telefonanlagen und Spracherkennung) Einrichtung von lokalen Netzwerken Vor-Ort-Support bei Problemen oder Störungen an IT-Endgeräten Mitarbeit bei Rollout-/Rollin-Projekten',
        skills: [
          'Kenntnisse über Microsoft Betriebssysteme, VM-Ware und HyperWare wären wünschenswert',
          'Gute MS-Office Kenntnisse',
          'Kundendienst Erfahrung von Vorteil',
          'handwerkliches Geschick',
          'Elektrotechnische Ausbildung ( Telekommunikations­techniker, Elektriker, IT Systemelektroniker)',
          'Eigeninitiative',
          'Spaß an der Arbeit',
          'Kundenorientiert',
          'Kommunikationsfähig',
          'Lust auf neues'
        ],
        offers: [
          'Sicherer Arbeitsplatz',
          'Urlaubs- und Weihnachtsgeld',
          'Beteiligung am Unternehmenserfolg',
          'Arbeiten innerhalb eines familiär geprägten Teams',
          'Stetige Weiterbildungsmöglichkeiten in Zusammenarbeit mit unseren Herstellern',
          'Leistungsgerechte Vergütung',
          'Betriebliche Altersvorsorge'
        ]
      },
      tabTitle: 'IT-Servicetechniker w/m'
    }
  },
  {
    path: '/service',
    name: 'Service',
    component: (): Promise<RouteComponent> => import('../views/service/Service.vue'),
    meta: {
      label: 'Service'
    },
    redirect: {
      name: 'ReportMeterReading'
    },
    children: [
      {
        path: 'zaehlerstand-melden',
        name: 'ReportMeterReading',
        component: (): Promise<RouteComponent> => import('../views/service/ReportMeterReading.vue'),
        meta: {
          label: 'Zählerstand melden',
          tabTitle: 'Zählerstand melden'
        }
      },
      {
        path: 'techniker-anfordern',
        name: 'RequestTechnician',
        component: (): Promise<RouteComponent> => import('../views/service/RequestTechnician.vue'),
        meta: {
          label: 'Techniker anfordern',
          tabTitle: 'Techniker anfordern'
        }
      },
      {
        path: 'toner-bestellen',
        name: 'OrderToner',
        component: (): Promise<RouteComponent> => import('../views/service/OrderToner.vue'),
        meta: {
          label: 'Toner bestellen',
          tabTitle: 'Toner bestellen'
        }
      },
      {
        path: 'rechnung-anfordern',
        name: 'RequestBill',
        component: (): Promise<RouteComponent> => import('../views/service/RequestBill.vue'),
        meta: {
          label: 'Rechnung anfordern',
          tabTitle: 'Rechnung anfordern'
        }
      },
      {
        path: 'fernwartung',
        name: 'RemoteMaintenance',
        component: (): Promise<RouteComponent> => import('../views/service/RemoteMaintenance.vue'),
        meta: {
          label: 'Fernwartung',
          tabTitle: 'Fernwartung'
        }
      }
    ]
  },
  {
    path: '/kontakt-anfahrt',
    name: 'Contact',
    component: (): Promise<RouteComponent> => import('../views/contact/Contact.vue'),
    meta: {
      label: 'Kontakt & Anfahrt',
      tabTitle: 'Kontakt & Anfahrt'
    }
  },
  {
    path: '/kontaktanfrage',
    name: 'ContactForm',
    component: (): Promise<RouteComponent> => import('../views/contact/ContactForm.vue'),
    meta: {
      label: 'Kontaktanfrage',
      tabTitle: 'Kontaktanfrage'
    }
  },
  {
    path: '/buerobedarf-exklusiv',
    name: 'OfficeSuppliesExclusive',
    component: (): Promise<RouteComponent> => import('../views/officeSuppliesExclusive/OfficeSuppliesExclusive.vue'),
    meta: {
      label: 'Bürobedarf exklusiv'
    },
    redirect: '/buerobedarf-exklusiv/produkte',
    children: [
      {
        path: 'produkte',
        name: 'OfficeSuppliesExclusiveProducts',
        component: (): Promise<RouteComponent> => import('../views/officeSuppliesExclusive/OfficeSuppliesExclusiveProducts.vue'),
        meta: {}
      },
      {
        path: 'warenkorb',
        name: 'OfficeSuppliesExclusiveBasket',
        component: (): Promise<RouteComponent> => import('../views/officeSuppliesExclusive/OfficeSuppliesExclusiveBasket.vue'),
        meta: {}
      },
      {
        path: 'danke',
        name: 'OfficeSuppliesExclusiveThankPage',
        component: (): Promise<RouteComponent> => import('../views/officeSuppliesExclusive/OfficeSuppliesExclusiveThankPage.vue'),
        meta: {}
      }
    ]
  },
  {
    path: '/agb',
    name: 'AGB',
    component: (): Promise<RouteComponent> => import('../views/AGB.vue'),
    meta: {
      label: 'agb'
    }
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: (): Promise<RouteComponent> => import('../views/Imprint.vue'),
    meta: {
      label: 'impressum'
    }
  },
  {
    path: '/datenschutz',
    name: 'DataProtection',
    component: (): Promise<RouteComponent> => import('../views/DataProtection.vue'),
    meta: {
      label: 'datenschutz'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: (): Promise<RouteComponent> => import('../views/PageNotFound.vue'),
    meta: {
      label: '404'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0, left: 0, behavior: 'instant' }
  }
})

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute?.meta && toRoute?.meta?.tabTitle ? `${toRoute.meta.tabTitle.toString()} | ${PAGE_TITLE_SUFFIX}` : PAGE_TITLE_SUFFIX;
  next();
});

export default router
